.transparent {
  background-color: transparent;
  a,
  h3 {
    color: white !important;
  }
  svg > path {
    fill: white;
  }
  box-shadow: none;
  .text {
    content: url("../../public/header/main_logo_text_transparent.png");
  }
}

.normal {
  background-color: white;
  a {
    color: var(--text-default-color);
  }
  svg > path {
    fill: var(--text-highlight-color);
  }
  box-shadow: var(--shadow);
  .text {
    content: url("../../public/header/main_logo_text_default.png");
  }
}

.header {
  font-weight: normal;
  width: 100%;
  height: 6.5rem;
  min-height: 60px;
  justify-content: center;
  display: flex;
  align-items: center;
  -webkit-transition: height 2s, background-color 1.25s, -webkit-transform 2s;
  position: fixed;
  transition: height 2s, background-color 1.25s;
  z-index: 3000;
  top: 0px;
  a,
  h3,
  svg > path {
    transition: color fill 1.25s;
    -webkit-transition: color fill 1.25s;
  }
  svg {
    height: 1.5rem;
    width: auto;
  }

  .container {
    width: min(100%, 1440px);
    height: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-transition: width 1s, height 1s, -webkit-transform 2s;
    transition: width 1s, height 1s;
  }

  .logoContainer {
    width: max-content;
    height: min(48px, 100%);
  }

  .logo {
    height: 100%;
    width: auto;
    --webkit-transition: src 1.25s;
    transition: src 1.25s;
  }
  .icon {
    content: url("../../public/header/main_logo.png");
  }

  .menuContainer {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .mobileMenuButton {
    display: block;
    height: 0px;
    width: 0px;
    opacity: 0%;
    -webkit-transition: all 1s ease, -webkit-transform 1s;
    transition: all 1s ease;
  }

  .webMenuButton {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 40px;
    width: max-content;
    -webkit-transition: all 1s ease, -webkit-transform 1s;
    transition: all 1s ease;
    > button {
      font-size: 1rem;
      align-items: center;
    }
  }

  .downloadButton {
    gap: 10px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  .download {
    gap: 4px;
    display: flex;
    line-height: 1rem;
    align-items: center;
  }

  .download > h3 {
    color: var(--text-highlight-color);
  }

  .downloadHiddenMenu {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    top: 5rem;
    position: absolute;
  }

  .show {
    display: flex;
    z-index: 1000;
  }

  .downloadHiddenMenu > a > img {
    box-shadow: var(--shadow);
  }
  .mobile {
    position: absolute;
    display: none;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 999px) {
    --header-height: max(1.5rem, 48px);
    height: 1.5rem;
    min-height: 48px;
    background-color: white;
    a {
      color: var(--text-default-color);
    }
    svg > path {
      fill: var(--text-default-color) !important;
    }
    box-shadow: var(--shadow);
    .text {
      content: url("../../public/header/main_logo_text_default.png");
    }

    .container {
      height: 60%;
      padding: 0 16px;
    }
    .menuContainer {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .mobile {
      display: block;
      right: 0;
      height: var(--header-height);
      width: var(--header-height);
      .mobileMenuButton {
        display: block;
        opacity: 100%;
        height: inherit;
        width: inherit;
        img {
          content: url("../../public/header/icon_menu.svg");
        }
      }
    }

    .webMenuButton {
      overflow: hidden;
      width: 0px;
    }
  }
}

@media screen and (max-width: 999px) {
  .mobileMenu {
    position: fixed;
    bottom: 100%;
    z-index: 20;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: white;
    box-shadow: inset 0 1rem 4rem #6b7eff0d;
    ul {
      height: 100vh;
    }
    li:first-child {
      height: var(--header-height);
    }
    li:not(:last-child) {
      border-bottom: 1px solid var(--bg-color);
    }
    li {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      a {
        width: 100%;
        padding: 3rem;
      }
      h3 {
        font-size: 2rem;
      }
      .buttons {
        padding: 0 2rem;
        display: flex;
        a {
          padding: 1rem;
          max-width: 25rem;
        }
      }
      svg {
        width: 100%;
        height: 100%;
      }
      svg > rect:first-child {
        width: 100%;
      }
    }
  }
}
