#section_1 {
  background: var(--bg-color);
  .phoneMockup {
    display: flex;
    aspect-ratio: 155 / 341;
  }

  // web view alignment
  @media screen and (min-width: 1000px) {
    .layout {
      grid-template-areas: "text image";
      grid-template-columns: 2fr 1fr;
    }
    .text {
      justify-self: start;
    }
    .image {
      justify-self: end;
      width: 80%;
    }
  }

  @media screen and (max-width: 999px) {
    .layout {
      grid-template-areas: "text" "image";
      grid-template-columns: 1fr;
      gap: 5rem;
    }
    .text span {
      white-space: pre;
    }
    .text span:nth-child(2) {
      white-space: normal;
    }
    .image {
      width: 90%;
    }
  }
}
