.div-wrapper .video-wrapper {
  width: 100%;
}

.div-wrapper .video-wrapper.single-video {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 0 1rem;
  position: relative;
}

.div-wrapper .video-2 {
  background-color: rgba(0, 0, 0, 0.7);
}

img.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지 크기가 container에 맞게 조정 */
}

.video {
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  align-items: center;
  gap: 8px;
  height: 10.5rem;
}

.div-wrapper .video.isSingle {
  width: 21.4rem;
  flex: 1;
  flex-grow: 1;
}

.div-wrapper .video.isSingle .bg-image {
  padding: 0 1.44rem;
}

.div-wrapper .video.isMulti {
  width: 18.5625rem;
  flex-shrink: 0;
}

.div-wrapper .video-player,
.div-wrapper .video-player > div {
  width: 100%;
  height: 100%;
}

.video .text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 168px;
  justify-content: flex-end;
  padding: 0px 16px 16px;
  position: relative;
  width: 100%;
}

.video .video-title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: #ffffff;
  display: -webkit-box;
  font-family: var(--title-16pt-font-family);
  font-size: var(--title-16pt-font-size);
  font-style: var(--title-16pt-font-style);
  font-weight: var(--title-16pt-font-weight);
  letter-spacing: var(--title-16pt-letter-spacing);
  line-height: var(--title-16pt-line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  text-align: center;
}

.video .icon {
  position: absolute;
  left: 50%;
  top: calc(50% - 0.38rem);
  display: flex;
  width: 4rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
