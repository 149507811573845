@import "./Slide6.scss";
@import "./Slide5.scss";
@import "./Slide4.scss";
@import "./Slide3.scss";
@import "./Slide2.scss";
@import "./Slide1.scss";
@import "./Slide0.scss";

.slideSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 50rem;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  overflow: hidden;
  padding: 3rem 3rem 3rem 3rem;
}
.layout {
  width: 100%;
  max-width: 1440px;
  height: 100%;
  max-height: 100%;
  justify-items: center;
  display: grid;
  align-items: center;
  z-index: inherit;
  padding-top: var(--header-height);
  box-sizing: border-box;
}
.image {
  grid-area: image;
  max-width: 100%;
  max-height: 100%;
}

.text {
  grid-area: text;
  .title > span {
    font-family: Gmarket Sans TTF;
    font-size: 2.75rem;
    font-weight: 700;
    line-height: 6rem;
    white-space: pre;
  }

  p {
    font-size: 1.5rem;
    font-weight: 100;
    line-height: 40px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    white-space: pre;
  }
}
.phoneMockup {
  min-width: 20rem;
  width: 80%;
  max-width: 80%;
  max-height: 80%;
  box-shadow: 3rem 3rem 3rem #00000030;
  border-radius: 3rem;
  border: 0.5rem solid white;
  background-color: white;
  aspect-ratio: var(--phone-aspect-ratio);
  overflow: hidden;
  position: relative;
  img {
    position: relative;
    width: 100%;
  }
}

@media screen and (min-width: 1000px) and (max-height: 50rem) {
  .phoneMockup {
    width: 100%;
    height: auto;
    top: 10vh;
  }
  .image {
    justify-self: center !important;
  }
}

.image > img {
  width: 100%;
  height: auto;

  top: 0px;
  left: 0px;
}

#section_0 {
  z-index: 10;
}

#section_1 {
  z-index: 9;
}

#section_2 {
  z-index: 8;
}

#section_3 {
  z-index: 7;
}

#section_4 {
  z-index: 6;
}

#section_5 {
  z-index: 5;
}

#section_6 {
  z-index: 4;
}

#section_7 {
  z-index: 5;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
}
section {
  @media screen and (max-width: 999px) {
    overflow: hidden;
    .text {
      gap: 1rem;
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      align-items: center;
      .title {
        text-align: center;
      }
      .title > span {
        font-size: 2.5rem;
        line-height: 3.75rem;
        white-space: normal;
        word-break: break-word;
      }
      p {
        font-size: 2.2rem;
        line-height: 3.5rem;
        white-space: normal;
        word-break: break-word;
        text-align: center;
      }
    }
    .image {
      width: 100%;
      height: 100%;
      justify-self: center;
      justify-items: center;
    }
    .phoneMockup {
      width: 100%;
      height: auto;
      border: 1rem solid white;
      border-radius: 5rem;
      max-height: unset;
      margin: 0 auto;
    }
  }
}
