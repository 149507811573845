#section_5 {
  background: #374259;

  .text span,
  .text p {
    color: white;
  }
  // web view alignment
  @media screen and (min-width: 1000px) {
    .layout {
      grid-template-areas: "text image";
      grid-template-columns: 2fr 1fr;
    }
    .text {
      justify-self: start;
    }
    .text > p {
      white-space: unset;
    }
    .image {
      justify-self: end;
      justify-items: flex-end;
      display: flex;
      width: 80%;
    }
  }

  @media screen and (max-width: 999px) {
    .layout {
      grid-template-areas: "text" "image";
      grid-template-columns: 1fr;
      grid-template-rows: 2fr 5fr;
      gap: 5rem;
    }
    .title > span {
      white-space: normal;
      word-break: break-word;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
