.div-wrapper .footer {
  left: 0;
  position: relative;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.div-wrapper .link {
  display: flex;
  width: 100%;
  padding-top: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
}

.div-wrapper .icon-logo {
  width: 1.5rem;
  height: 1.5rem;
}

.div-wrapper .move {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  padding-left: 0.75rem;
  padding-right: 0;
  position: relative;
}

.div-wrapper .move p {
  color: var(--Text-disabled, #999);
  font-size: 0.875rem;
  font-weight: 500;
}

.div-wrapper .button-div {
  align-items: flex-start;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
}

.div-wrapper .connect-button {
  margin-bottom: 1rem;
  align-items: center;
  background-color: var(--primaryprimary);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  width: 21.4375rem;
  height: 3.5rem;
  cursor: pointer;
}

.div-wrapper .connect-button p {
  color: #ffffff;
  font-family: var(--title-16pt-font-family);
  font-size: var(--title-16pt-font-size);
  font-style: var(--title-16pt-font-style);
  font-weight: var(--title-16pt-font-weight);
  letter-spacing: var(--title-16pt-letter-spacing);
  line-height: var(--title-16pt-line-height);
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
