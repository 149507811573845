:root {
  --text-default-color: #374259;
  --text-highlight-color: #6b7eff;
  --text-secondary-color: #8f9aa2;
  --shadow: 0px 4px 16px 0px #6b7eff0d;
  --mobile-max-width: 425px;
  --phone-aspect-ratio: 231 / 495;
}

@font-face {
  font-family: Gmarket Sans TTF;
  src: url("../../public/fonts/GmarketSansBold.otf") format("opentype");
  src: url("../../public/fonts/GmarketSansTTFBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Gmarket Sans TTF;
  src: url("../../public/fonts/GmarketSansMedium.otf") format("opentype");
  src: url("../../public/fonts/GmarketSansTTFMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Gmarket Sans TTF;
  src: url("../../public/fonts/GmarketSansLight.otf") format("opentype");
  src: url("../../public/fonts/GmarketSansTTFLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
html {
  transition: all 0.3s;
  --webkit-transition: all 0.3s;
}

@media screen and (min-width: 1921px) {
  html {
    font-size: round(0.6vw, 1px);
    --header-height: 4rem;
    --bg-color: #f7f7fa;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1920px) {
  html {
    font-size: round(0.83vw, 1px);
    --header-height: 6.5rem;
    --bg-color: #f7f7fa;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1199px) {
  html {
    font-size: round(1vw, 1px);
    --header-height: 6.5rem;
    --bg-color: #f7f7fa;
  }
}

@media screen and (min-width: 450px) and (max-width: 999px) {
  html {
    font-size: round(1.5vw, 1px);
    --header-height: max(1.5rem, 48px);
    --bg-color: #efeff7;
  }
}

@media screen and (max-width: 449px) {
  html {
    font-size: round(1.8vw, 1px);
    --header-height: max(1.5rem, 48px);
    --bg-color: #efeff7;
  }
}

body {
  margin: 0;
  /* font-family: Gmarket Sans TTF, -apple-system, BlinkMacSystemFont, sans-serif; */
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-default-color);
  background-color: var(--bg-color);
}

html {
  min-width: 220px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.textHighlight {
  color: var(--text-highlight-color) !important;
}

main {
  display: flex;
}
