#section_2 {
  background: var(--bg-color);

  .phoneMockup {
    background-image: url("../../../public/slides/2/bg.png");
    background-size: cover;
    background-position: center;
  }
  .phoneMockup > * {
    display: none;
  }

  // web view alignment
  @media screen and (min-width: 1000px) {
    .layout {
      grid-template-areas: "image text";
      grid-template-columns: 1fr 2fr;
    }
    .text {
      justify-self: end;
    }
    .image {
      justify-self: center;
      width: 80%;
      justify-items: center;
    }
  }

  @media screen and (max-width: 999px) {
    .layout {
      grid-template-areas: "text" "image";
      grid-template-columns: 1fr;
      gap: 5rem;
    }
    .text span {
      white-space: pre;
    }
    .text span:nth-child(2) {
      white-space: normal;
    }
    .image {
      width: 90%;
    }
    img {
      width: 100%;
    }
  }
}
