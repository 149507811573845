.scrollProgress {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100vw;
  height: 4px;
  background: transparent;
  z-index: 100;
}

.scrollProgressBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--text-highlight-color);
  z-index: 100;
  transition: width 1s ease-in-out;
  transition-delay: 0.25s;
}

@media screen and (max-width: 999px) {
  .scrollProgress {
    height: 2px;
  }
}
