#section_6 {
  .layout {
    grid-template-areas: "text" "image";
    grid-gap: 4rem;
    height: max-content;
  }
  .text p,
  .text span {
    text-align: center;
  }
  .text p {
    font-weight: 500;
  }

  button {
    background-color: var(--text-highlight-color);
    font-size: 1rem;
    font-weight: 700;
    color: white;
    font-family: Gmarket Sans TTF;
    padding: 1.3rem 2.5rem;
    border-radius: 3rem;
    box-shadow: 0rem 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  @media screen and (max-width: 999px) {
    .layout {
      grid-template-areas: "text" "image";
      grid-template-columns: 1fr;

      align-self: baseline;
      padding-bottom: var(--header-height);

      .text {
        align-self: center;
      }
    }
    .image {
      width: max-content;
    }
    .text p {
      font-weight: 500;
    }
  }
}
