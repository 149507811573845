.div-wrapper .spot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.div-wrapper .spot-item {
  display: flex;
  width: 10.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.75rem;
  flex-shrink: 0;
}

.div-wrapper .spot-img-wrapper {
  position: relative;
  width: 100%;
  height: 13.625rem;
  border-radius: 0.75rem;
  overflow: hidden;
}

.div-wrapper .spot-img-wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.div-wrapper .spot-image {
  width: 100%;
  height: 13.625rem;
  object-fit: cover;
  border-radius: 0.75rem;
  overflow: hidden;
}

.div-wrapper .spot-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.div-wrapper .spot-info {
  padding: 0rem 0.25rem;
  position: relative;
  width: 100%;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.125rem;
}

.div-wrapper .spot-title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--texttitle);
  display: -webkit-box;
  font-family: var(--title-16pt-font-family);
  font-size: var(--title-16pt-font-size);
  font-style: var(--title-16pt-font-style);
  font-weight: var(--title-16pt-font-weight);
  letter-spacing: var(--title-16pt-letter-spacing);
  line-height: var(--title-16pt-line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.div-wrapper .desc {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--textdisabled);
  display: -webkit-box;
  font-family: var(--body-14pt-font-family);
  font-size: var(--body-14pt-font-size);
  font-style: var(--body-14pt-font-style);
  font-weight: var(--body-14pt-font-weight);
  letter-spacing: var(--body-14pt-letter-spacing);
  line-height: var(--body-14pt-line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}
