#section_0 {
  @media screen and (min-width: 1000px) {
    .layout {
      grid-template-areas: "text image";
      grid-template-columns: 1fr 1fr;
    }
    .text {
      justify-self: start;
    }
    .image {
      justify-self: end;
    }
  }
  .bg1 {
    background: rgba(0, 0, 0, 0.5) url("../../../public/slides/main/bg1.jpeg")
      center/cover;
    background-blend-mode: overlay;
    left: 0;
  }

  .bg2 {
    background-image: linear-gradient(
        106.75deg,
        #8c4bff 0%,
        #5498ff 62.67%,
        #31ffc1 125.34%
      ),
      url("../../../public/slides/main/bg2.svg");
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
    position: absolute;
    left: 100%;
  }

  .bg3 {
    background: rgba(0, 0, 0, 0.5) url("../../../public/slides/main/bg3.jpg")
      center/cover;
    background-blend-mode: overlay;
    left: 200%;
  }
  .bg4 {
    background: rgba(0, 0, 0, 0.5) url("../../../public/slides/main/bg1.jpeg")
      center/cover;
    background-blend-mode: overlay;
    left: 300%;
  }

  .text span,
  .text p {
    color: white;
  }

  .text p {
    font-weight: 500;
  }

  .image {
    width: 55rem;
    height: auto;
    max-width: 50vw;
    max-height: 70vh;
    top: 8rem;
    position: relative;
    object-fit: contain;
    img {
      overflow: clip;
      overflow-clip-margin: content-box;
    }
  }
  @media screen and (max-width: 999px) {
    //align mobile view
    .layout {
      grid-template-areas: "text" "image";
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr;
      gap: 2rem;
    }

    .text {
      align-items: start;
      .title {
        text-align: left;
      }
      p {
        font-size: 2.2rem;
        line-height: 3.5rem;
        white-space: normal;
        word-break: break-word;
        text-align: left;
      }
    }
    .image {
      width: 120%;
      max-width: 100vw;
      max-height: 70vh;
      top: -4rem;
      left: 7%;
    }
  }
}
