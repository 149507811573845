.div-wrapper .content-wrapper {
  position: relative;
  margin-top: -1.5rem;
  display: flex;
  width: 100%;
  padding: 1.5rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  background-color: #ffffff;
}

.div-wrapper .divider {
  align-self: stretch;
  background-color: var(--graybg);
  height: 0.5rem;
  width: 100%;
}

::-webkit-scrollbar {
  display: none;
}

.div-wrapper .title {
  padding: 0 0 0 1.5rem;
}

.div-wrapper .title p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: var(--texttitle);
  display: -webkit-box;
  font-family: var(--title-18pt-font-family);
  font-size: var(--title-18pt-font-size);
  font-style: var(--title-18pt-font-style);
  font-weight: var(--title-18pt-font-weight);
  letter-spacing: var(--title-18pt-letter-spacing);
  line-height: var(--title-18pt-line-height);
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
}

.div-wrapper .list {
  align-items: flex-start;
  padding: 0rem 1rem;
  display: flex;
  gap: 0.5rem;
  position: relative;
  overflow-x: auto;
  width: 100%;
}
