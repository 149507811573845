:root {
  --15: 0px 4px 12px 0px rgba(107, 126, 255, 0.15);
  --5: 0px -4px 12px 0px rgba(0, 0, 0, 0.05);
  --body-10pt-font-family: "Pretendard", Helvetica;
  --body-10pt-font-size: 10px;
  --body-10pt-font-style: normal;
  --body-10pt-font-weight: 400;
  --body-10pt-letter-spacing: 0px;
  --body-10pt-line-height: 16px;
  --body-12pt-font-family: "Pretendard", Helvetica;
  --body-12pt-font-size: 12px;
  --body-12pt-font-style: normal;
  --body-12pt-font-weight: 400;
  --body-12pt-letter-spacing: 0px;
  --body-12pt-line-height: 18px;
  --body-14pt-font-family: "Pretendard", Helvetica;
  --body-14pt-font-size: 14px;
  --body-14pt-font-style: normal;
  --body-14pt-font-weight: 400;
  --body-14pt-letter-spacing: 0px;
  --body-14pt-line-height: 22px;
  --body-16pt-font-family: "Pretendard", Helvetica;
  --body-16pt-font-size: 16px;
  --body-16pt-font-style: normal;
  --body-16pt-font-weight: 400;
  --body-16pt-letter-spacing: 0px;
  --body-16pt-line-height: 24px;
  --collection-cart: 1px;
  --gray-00030: rgba(0, 0, 0, 0.3);
  --gray-00050: rgba(0, 0, 0, 0.5);
  --gray-00070: rgba(0, 0, 0, 0.7);
  --graybg: rgba(247, 247, 250, 1);
  --grayblack: rgba(0, 0, 0, 1);
  --graycccccc: rgba(204, 204, 204, 1);
  --graydbdbdb: rgba(219, 219, 219, 1);
  --grayededed: rgba(237, 237, 237, 1);
  --grayefeff-7: rgba(239, 239, 247, 1);
  --grayfff-30: rgba(255, 255, 255, 0.3);
  --grayfff-50: rgba(255, 255, 255, 0.5);
  --grayfff-70: rgba(255, 255, 255, 0.7);
  --graywhite: rgba(255, 255, 255, 1);
  --h-h1-font-family: "Pretendard", Helvetica;
  --h-h1-font-size: 28px;
  --h-h1-font-style: normal;
  --h-h1-font-weight: 700;
  --h-h1-letter-spacing: 0px;
  --h-h1-line-height: 38px;
  --h-h2-font-family: "Pretendard", Helvetica;
  --h-h2-font-size: 24px;
  --h-h2-font-style: normal;
  --h-h2-font-weight: 700;
  --h-h2-letter-spacing: 0px;
  --h-h2-line-height: 34px;
  --h-h3-font-family: "Pretendard", Helvetica;
  --h-h3-font-size: 22px;
  --h-h3-font-style: normal;
  --h-h3-font-weight: 700;
  --h-h3-letter-spacing: 0px;
  --h-h3-line-height: 32px;
  --othererror: rgba(255, 64, 79, 1);
  --othererror-12: rgba(255, 232, 234, 1);
  --otherlabel: rgba(255, 184, 0, 1);
  --primary-12: rgba(107, 126, 255, 0.12);
  --primaryprimary: rgba(107, 126, 255, 1);
  --shape-12: 12px;
  --shape-16: 16px;
  --shape-24: 24px;
  --shape-4: 4px;
  --shape-8: 8px;
  --sub-0-0d-7ff: rgba(0, 215, 255, 1);
  --sub-0-0ffab: rgba(0, 255, 171, 1);
  --sub-1eae-98: rgba(30, 174, 152, 1);
  --sub-859-5fe: rgba(133, 149, 254, 1);
  --subtitle-12pt-font-family: "Pretendard", Helvetica;
  --subtitle-12pt-font-size: 12px;
  --subtitle-12pt-font-style: normal;
  --subtitle-12pt-font-weight: 500;
  --subtitle-12pt-letter-spacing: 0px;
  --subtitle-12pt-line-height: 18px;
  --subtitle-14pt-font-family: "Pretendard", Helvetica;
  --subtitle-14pt-font-size: 14px;
  --subtitle-14pt-font-style: normal;
  --subtitle-14pt-font-weight: 500;
  --subtitle-14pt-letter-spacing: 0px;
  --subtitle-14pt-line-height: 22px;
  --subtitle-16pt-font-family: "Pretendard", Helvetica;
  --subtitle-16pt-font-size: 16px;
  --subtitle-16pt-font-style: normal;
  --subtitle-16pt-font-weight: 500;
  --subtitle-16pt-letter-spacing: 0px;
  --subtitle-16pt-line-height: 24px;
  --subtitle-18pt-font-family: "Pretendard", Helvetica;
  --subtitle-18pt-font-size: 18px;
  --subtitle-18pt-font-style: normal;
  --subtitle-18pt-font-weight: 500;
  --subtitle-18pt-letter-spacing: 0px;
  --subtitle-18pt-line-height: 26px;
  --textbody: rgba(84, 91, 119, 1);
  --textdisabled: rgba(153, 153, 153, 1);
  --texttitle: rgba(55, 66, 89, 1);
  --title-10pt-font-family: "Pretendard", Helvetica;
  --title-10pt-font-size: 10px;
  --title-10pt-font-style: normal;
  --title-10pt-font-weight: 700;
  --title-10pt-letter-spacing: 0px;
  --title-10pt-line-height: 16px;
  --title-12pt-font-family: "Pretendard", Helvetica;
  --title-12pt-font-size: 12px;
  --title-12pt-font-style: normal;
  --title-12pt-font-weight: 700;
  --title-12pt-letter-spacing: 0px;
  --title-12pt-line-height: 18px;
  --title-14pt-font-family: "Pretendard", Helvetica;
  --title-14pt-font-size: 14px;
  --title-14pt-font-style: normal;
  --title-14pt-font-weight: 700;
  --title-14pt-letter-spacing: 0px;
  --title-14pt-line-height: 22px;
  --title-16pt-font-family: "Pretendard", Helvetica;
  --title-16pt-font-size: 16px;
  --title-16pt-font-style: normal;
  --title-16pt-font-weight: 700;
  --title-16pt-letter-spacing: 0px;
  --title-16pt-line-height: 24px;
  --title-18pt-font-family: "Pretendard", Helvetica;
  --title-18pt-font-size: 18px;
  --title-18pt-font-style: normal;
  --title-18pt-font-weight: 700;
  --title-18pt-letter-spacing: 0px;
  --title-18pt-line-height: 26px;
}

@import url("https://fonts.googleapis.com/css?family=Briem+Hand:600");
@font-face {
  font-family: "SF Pro Text-Semibold";
  src: url("https://anima-uploads.s3.amazonaws.com/projects/65b92b4b6fc441ea8a246f8e/fonts/century-gothic.ttf")
    format("truetype");
}
@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}
html,
body {
  margin: 0px;
  height: 100%;
  font-size: 16px;
}
/* a blue color as a generic focus style */
button:focus-visible {
  outline: 2px solid #4a90e2 !important;
  outline: -webkit-focus-ring-color auto 5px !important;
}
a {
  text-decoration: none;
}

@media screen and (max-width: 999px) {
  html[data-domain-style="true"],
  body[data-domain-style="true"] {
    font-size: 8px;
  }
}
