.div-wrapper .profile {
  display: flex;
  padding: 4.25rem 1.5rem 4rem 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.div-wrapper .profile.empty-contents {
  padding-bottom: 4rem;
}

.div-wrapper .blured-bg {
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

img.background-img:before,
img.profile-img:before {
  content: " ";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
}

img.background-img:before {
  background-image: url(/src/assets/profile/default_bg.jpg);
}

img.profile-img:before {
  background-image: url(/src/assets/profile/default_profile.jpg);
}

.div-wrapper .background-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.div-wrapper .blur-div {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(40px) brightness(100%);
  backdrop-filter: blur(40px) brightness(100%);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.div-wrapper .profile .profile-img {
  width: 12.5rem;
  height: 15.5rem;
  border-radius: 1.5rem;
  position: relative;
}

.div-wrapper .profile .contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}

.div-wrapper .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.div-wrapper .name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.375rem;
  align-self: stretch;
  position: relative;
}

.div-wrapper .name p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-align: center;
  text-overflow: ellipsis;
  color: #ffffff;
  display: -webkit-box;
  font-family: "Briem Hand", Helvetica;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.div-wrapper .badge {
  display: flex;
  height: 3rem;
  padding-bottom: 0.4375rem;
  align-items: flex-end;
  gap: 0.5rem;
}

.div-wrapper .full-name {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: #ffffff;
  display: -webkit-box;
  font-family: var(--subtitle-14pt-font-family);
  font-size: 0.875rem;
  font-style: var(--subtitle-14pt-font-style);
  font-weight: var(--subtitle-14pt-font-weight);
  letter-spacing: var(--subtitle-14pt-letter-spacing);
  line-height: var(--subtitle-14pt-line-height);
  overflow: hidden;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
}

.div-wrapper .intro {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--body-14pt-font-family);
  font-size: 0.875rem;
  font-style: var(--body-14pt-font-style);
  font-weight: var(--body-14pt-font-weight);
  letter-spacing: var(--body-14pt-letter-spacing);
  line-height: var(--body-14pt-line-height);
  position: relative;
  text-align: center;
}

.div-wrapper .sns {
  display: flex;
  padding-top: 0.5rem;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.div-wrapper .sns-button {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 3rem;
  background: var(--Gray-white, #fff);
}

.div-wrapper .sns-button a {
  height: 1.5rem;
}

.div-wrapper .sns-button .sns-img {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

.div-wrapper .share-button {
  position: absolute;
  top: 2.75rem;
  right: 1rem;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  background: var(--Gray-white, #fff);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.div-wrapper .share-button img.share-img {
  width: 1.25rem;
  height: 1.25rem;
}

.share-button:active {
  transform: translateY(0.1rem);
}
