div#root {
  width: 100%;
}

.div-wrapper {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  margin: 0px;
  height: 100%;
  font-size: 16px; /* 기본적으로 1rem = 16px */
  max-width: 27rem;
  display: flex;
  justify-self: center;
}

.div-wrapper .div {
  background-color: #ffffff;
  width: 100%;
}

.div-wrapper .contents-body {
  width: 100%;
}
