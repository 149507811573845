.footer {
  width: 100vw;
  height: max-content;
  background: #f7f7fa;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  // position: relative;
}

footer {
  width: 100%;
  max-width: 1440px;
  align-items: center;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;

  .content {
    width: 100%;
    position: relative;
    align-items: left;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    .title > a {
      font-weight: bold;
    }
    p {
      gap: 1rem;
      display: flex;
      span,
      a {
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: left;
        white-space: pre;
        min-width: 4rem;
      }
    }
    p:not(.title) > span {
      color: #999999;
    }
  }

  .icons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 1rem;
  }

  @media screen and (max-width: 999px) {
    max-width: 980px;
    width: 90%;
    .content {
      p {
        span,
        a {
          word-break: break-words;
          white-space: inherit;
          font-size: 1.5rem;
          line-height: 2rem;
          min-width: 8rem;
        }
      }
    }
    .icons {
      position: relative;
      margin: 1rem 0;
      svg {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
