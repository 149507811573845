#section_4 {
  background: #374259;
  // web view alignment
  @media screen and (min-width: 1000px) {
    .layout {
      grid-template-areas: "text image";
      grid-template-columns: 1fr 1fr;
    }
    .text {
      justify-self: start;
    }
    .image {
      justify-self: end;
    }
  }
  .text span,
  .text p {
    color: white;
  }

  .title span {
    white-space: pre;
  }
  .text {
    position: relative;
  }

  .image {
    max-width: 50rem;
    max-height: 50rem;
    top: 4rem;
    position: relative;
    object-fit: contain;
    filter: drop-shadow(3rem 3rem 3rem #00000030);
    -webkit-filter: drop-shadow(3rem 3rem 3rem #00000030);
  }

  img {
    width: 100%;
    height: auto;
    overflow: clip;
    overflow-clip-margin: content-box;
  }

  @media screen and (max-width: 999px) {
    .layout {
      grid-template-areas: "text" "image";
      grid-template-columns: 1fr;
      gap: 5rem;
    }
  }
}
