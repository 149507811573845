#section_3 {
  background: var(--bg-color);

  .image {
    width: max-content;
    height: 100%;
    align-items: center;
    display: flex;
    overflow: visible;
    ul {
      display: flex;
      height: 22rem;
      width: auto;
    }
    ul,
    li {
      justify-content: center;
      align-items: center;
      display: flex;
    }
    li {
      height: 100%;
      width: auto;
      aspect-ratio: 1/1;
      position: relative;
    }
  }
  .emoji_3 {
    position: unset;
    left: 0;
    transform: unset;
    width: 50%;
    aspect-ratio: 1/1;
    img {
      width: 50%;
      height: auto;
      position: absolute;
      opacity: 0;
    }
  }

  ul li:first-child {
    border-radius: 3rem;
    background-color: white;
    box-shadow: 2rem 2rem 3rem #6b7eff1f;

    top: 0;
  }

  // web view alignment
  @media screen and (min-width: 1000px) {
    .layout {
      grid-template-areas: "text image";
      grid-template-columns: 1fr 1fr;
      grid-gap: 4rem;
    }
    .text {
      justify-self: start;
    }
    .text > p {
      white-space: unset;
    }
    .image {
      justify-self: end;
    }
  }

  @media screen and (max-width: 999px) {
    background: #efeff7;
    .layout {
      grid-template-areas: "text" "image";
      grid-template-columns: 1fr;
      grid-template-rows: 2fr 5fr;
      grid-gap: 6rem;
    }
    .text {
      .title span {
        font-size: 2.8rem;
      }
    }
    span {
      white-space: normal;
    }
    .text p {
      white-space: pre;
    }

    .image {
      width: 100%;
      flex-direction: column;
      aspect-ratio: 1/1;
      ul {
        position: absolute;
        display: contents;
        width: 80%;
        height: max-content;
        margin: 0 auto;
        //background
        ul li:first-child {
          width: 100%;
          height: auto;
          border-radius: 6rem;
        }
        li:not(:first-child) {
          display: none;
        }
      }
      li {
        width: 100%;
        aspect-ratio: 1/1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .emoji_3 {
        position: unset;
        left: 0;
        transform: unset;
        width: 50%;
        aspect-ratio: 1/1;
        img {
          width: 50%;
          height: auto;
          position: absolute;
        }
      }
    }
  }
}
